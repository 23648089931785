import React from "react";
import { ApolloProvider, useQuery } from "@apollo/client";
import queryString from "query-string";

import client from "../gatsby-theme-apollo/client";
import SEO from "../components/seo";
import { PreviewBar } from "../components/PreviewBar";
import * as MemberInstitutionPage from "../templates/MemberInstitutionPage";
import * as MemberInstitutionListingPage from "../templates/MemberInstitutionListingPage";
import * as PersonPage from "../templates/PersonPage";
import * as PersonListingPage from "../templates/PersonListingPage";
import * as PublicationPage from "../templates/PublicationPage";
import * as PublicationListingPage from "../templates/PublicationListingPage";
import * as PresentationPage from "../templates/PresentationPage";
import * as PresentationListingPage from "../templates/PresentationListingPage";
import * as StandardPage from "../templates/StandardPage";
import * as HomePage from "../templates/HomePage";
import * as ExperimentListingPage from "../templates/ExperimentListingPage";
import * as ExperimentPage from "../templates/ExperimentPage";
import * as HistologyListingPage from "../templates/HistologyListingPage";
import * as HistologyPage from "../templates/HistologyPage";
import * as InformaticsPortalsPage from "../templates/InformaticsPortalsPage";
import * as ResearchPartnershipsPage from "../templates/ResearchPartnershipsPage";
import * as FoundationListingPage from "../templates/FoundationListingPage";
import * as FoundationPage from "../templates/FoundationPartnershipPage";
import * as ArticleListingPage from "../templates/ArticleListingPage";
import * as ArticlePage from "../templates/ArticlePage";

// This could be a subscription, but there's currently something wrong with
// the wagtail-grapple library and channels for websockets
const previews = {
  "cbtn.homepage": HomePage,
  "cbtn.memberinstitutionpage": MemberInstitutionPage,
  "cbtn.memberinstitutionlistingpage": MemberInstitutionListingPage,
  "cbtn.personpage": PersonPage,
  "cbtn.personlistingpage": PersonListingPage,
  "cbtn.publicationpage": PublicationPage,
  "cbtn.publicationlistingpage": PublicationListingPage,
  "cbtn.presentationpage": PresentationPage,
  "cbtn.presentationlistingpage": PresentationListingPage,
  "cbtn.standardpage": StandardPage,
  "cbtn.experimentlistingpage": ExperimentListingPage,
  "cbtn.experimentpage": ExperimentPage,
  "cbtn.histologylistingpage": HistologyListingPage,
  "cbtn.histologypage": HistologyPage,
  "cbtn.informaticsportalspage": InformaticsPortalsPage,
  "cbtn.researchpartnershipspage": ResearchPartnershipsPage,
  "cbtn.foundationpartnershiplistingpage": FoundationListingPage,
  "cbtn.foundationpartnershippage": FoundationPage,
  "cbtn.articlelistingpage": ArticleListingPage,
  "cbtn.articlepage": ArticlePage,
};

const Preview = ({ location }) => {
  const qs = queryString.parse(location.search);

  const { previewQuery, Component } = previews[
    qs.content_type || "cbtn.memberinstitutionpage"
  ];

  const { loading, error, data } = useQuery(previewQuery, {
    variables: { contentType: qs.content_type, token: qs.token },
  });

  if (loading) return "Loading...";
  if (error) return JSON.stringify(error);

  return <Component {...data} />;
};

const PreviewPage = ({ location }) => {
  return (
    <>
      <SEO title="Preview" />
      <PreviewBar />
      <ApolloProvider client={client}>
        <Preview location={location} />
      </ApolloProvider>
    </>
  );
};

export default PreviewPage;
