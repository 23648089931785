import React, { Fragment, useState, useRef, useEffect } from "react";
import { navigate } from "@reach/router";
import { useDebouncedCallback } from "use-debounce";
import { Dialog, Transition } from "@headlessui/react";
import { MenuAlt1Icon, XIcon } from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import { useSetRecoilState } from "recoil";
import { useMediaQuery } from "@react-hook/media-query";
import qs from "qs";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import {
  InstantSearch,
  Hits,
  Results,
  Configure,
  RefinementPanel,
  InlineSearchInput,
  PubPresHit,
  states,
  Pagination,
} from "../components/next/Algolia";

const refinements = [
  {
    Panel: { header: "Authors" },
    RefinementList: {
      attribute: "authors.author.title",
      searchable: true,
    },
  },
  {
    Panel: { header: "Institution" },
    RefinementList: {
      attribute: "authors.author.memberInstitution.title",
      searchable: true,
    },
  },
  {
    Panel: { header: "Journal" },
    RefinementList: {
      attribute: "journal.name",
      searchable: true,
    },
  },
];

const createURL = (state) => {
  const isDefaultRoute =
    !(state.page > 1) &&
    state.query === "" &&
    Object.values(state.refinementList).filter((rl: string) => rl?.length > 0)
      .length === 0;

  if (isDefaultRoute) {
    // this can't be empty string for some reason
    return "?";
  }

  const queryParameters: any = {};

  if (state.query?.length > 0) {
    queryParameters.query = state.query;
  }
  if (state.page !== 1) {
    queryParameters.page = state.page;
  }
  if (state.refinementList["authors.author.title"]) {
    queryParameters.author = state.refinementList["authors.author.title"].map(
      encodeURIComponent
    );
  }
  if (state.refinementList["authors.author.memberInstitution.title"]) {
    queryParameters.institution = state.refinementList[
      "authors.author.memberInstitution.title"
    ].map(encodeURIComponent);
  }
  if (state.refinementList["journal.name"]) {
    queryParameters.journal = state.refinementList["journal.name"].map(
      encodeURIComponent
    );
  }

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  });

  return queryString;
};

const searchStateToUrl = (searchState) =>
  searchState ? createURL(searchState) : "";

const urlToSearchState = (params) => {
  const {
    query = "",
    page = 1,
    author = [],
    institution = [],
    journal = [],
  } = params;

  // `qs` does not return an array when there's a single value.
  const allAuthor = Array.isArray(author) ? author : [author].filter(Boolean);
  const allInstitution = Array.isArray(institution)
    ? institution
    : [institution].filter(Boolean);
  const allJournal = Array.isArray(journal)
    ? journal
    : [journal].filter(Boolean);

  return {
    query: decodeURIComponent(query),
    page,
    refinementList: {
      "authors.author.title": allAuthor.map(decodeURIComponent),
      "authors.author.memberInstitution.title": allInstitution.map(
        decodeURIComponent
      ),
      "journal.name": allJournal.map(decodeURIComponent),
    },
  };
};

const PublicationsPage = ({ location }) => {
  const matches = useMediaQuery("only screen and (max-width: 1024px)");
  const closeButtonRef = useRef();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const setFacetSearchInputValue = useSetRecoilState(
    states.facetSearchInputValueState
  );
  const onFacetSearchInputChange = useDebouncedCallback(
    // function
    (value) => {
      setFacetSearchInputValue(value);
    },
    // delay in ms
    400
  );

  const [searchState, setSearchState] = useState(
    urlToSearchState(qs.parse(location.search.slice(1)))
  );

  useEffect(() => {
    setSearchState(urlToSearchState(qs.parse(location.search.slice(1))));
  }, [location.search, setSearchState]);

  const [debouncedSetState, setDebouncedSetState] = useState(null);

  const onSearchStateChange = (updatedSearchState) => {
    clearTimeout(debouncedSetState);

    setDebouncedSetState(
      setTimeout(() => {
        navigate(searchStateToUrl(updatedSearchState));
      }, 400)
    );

    setSearchState(updatedSearchState);
  };

  return (
    <div className="font-sans antialiased flex flex-col h-full">
      <Header />
      <InstantSearch
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
        indexName={`${process.env.GATSBY_ALGOLIA_INDEX_NAME}_dateTimestamp_desc`}
      >
        <Configure filters={"typename:Publication"} />
        <div className="min-h-screen flex">
          {matches && (
            <Transition.Root show={sidebarOpen} as={Fragment} unmount={false}>
              <Dialog
                as="div"
                static
                className="fixed inset-0 flex z-40 lg:hidden"
                open={sidebarOpen}
                onClose={setSidebarOpen}
                initialFocus={closeButtonRef}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  unmount={false}
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                  unmount={false}
                >
                  <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      unmount={false}
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          ref={closeButtonRef}
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div>
                      <div className="flex-1 flex justify-between px-2">
                        <div className="flex-1 flex">
                          <form
                            className="w-full flex md:ml-0"
                            noValidate
                            action=""
                            role="search"
                            onSubmit={(e) => {
                              e.preventDefault();
                              (event) =>
                                onFacetSearchInputChange(
                                  event.currentTarget.value
                                );
                            }}
                          >
                            <label htmlFor="search_field" className="sr-only">
                              Search
                            </label>
                            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                              <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                                <SearchIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </div>
                              <input
                                name="search"
                                className="block w-full h-full pl-8 pr-3 py-2 border-transparent bg-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:text-sm"
                                placeholder="Find values"
                                type="search"
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="none"
                                spellCheck="false"
                                onChange={(event) => {
                                  onFacetSearchInputChange(event.target.value);
                                }}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="h-4" />
                    <div className="h-0 flex-1 flex flex-col overflow-y-auto space-y-4">
                      {refinements.map((r) => (
                        <RefinementPanel
                          key={r.Panel.header}
                          {...r.RefinementList}
                          {...r.Panel}
                        />
                      ))}
                    </div>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </Dialog>
            </Transition.Root>
          )}
          {/* Static sidebar for desktop */}
          {!matches && (
            <aside className="hidden lg:flex lg:flex-shrink-0">
              <div className="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-100 2xl:w-80">
                <div>
                  <div className="flex-1 flex justify-between px-2">
                    <div className="flex-1 flex">
                      <form
                        className="w-full flex md:ml-0"
                        noValidate
                        action=""
                        role="search"
                        onSubmit={(e) => {
                          e.preventDefault();
                          (event) =>
                            onFacetSearchInputChange(event.currentTarget.value);
                        }}
                      >
                        <label htmlFor="search_field" className="sr-only">
                          Search
                        </label>
                        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                            <SearchIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            name="search"
                            className="block w-full h-full pl-8 pr-3 py-2 border-transparent bg-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:text-sm"
                            placeholder="Find values"
                            type="search"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="none"
                            spellCheck="false"
                            onChange={(event) => {
                              onFacetSearchInputChange(event.target.value);
                            }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="h-4" />
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="h-0 flex-1 flex flex-col overflow-y-auto space-y-4">
                  {refinements.map((r) => (
                    <RefinementPanel
                      key={r.Panel.header}
                      {...r.RefinementList}
                      {...r.Panel}
                    />
                  ))}
                </div>
              </div>
            </aside>
          )}
          {/* Main column */}
          <div className="flex flex-col w-0 flex-1">
            <main className="flex-1 relative z-0 focus:outline-none">
              {/* Page title & actions */}
              <div className="border-b border-gray-200 px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center sm:justify-between py-4 sm:py-6">
                  <div className="flex-1 min-w-0">
                    <h1 className="font-jaf text-4xl leading-6 text-gray-900sm:truncate">
                      Publications
                    </h1>
                  </div>
                </div>

                <p className="text-base text-gray-700">
                  Since its launch in 2011, the CBTN (formally the Children’s
                  Brain Tumor Tissue Consortium) has supported more than 170
                  studies into childhood brain and CNS tumors. The scientists
                  who have led these studies have made a staggering number of
                  critical discoveries, and have had their work published in
                  some of the leading academic biomedical publications in the
                  world. CBTN strives to have full publications made available
                  below.
                </p>
                <div className="h-4 sm:h-6" />
              </div>
              {/* Search header */}
              <div className="relative flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
                <button
                  className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <InlineSearchInput />
              </div>
              {/* Results */}
              <div className="m-6 sm:m-8">
                <Results>
                  <Hits Hit={PubPresHit} path="publications" addLabel={false} />
                  <Pagination />
                </Results>
              </div>
            </main>
          </div>
        </div>
      </InstantSearch>
      <Footer />
    </div>
  );
};

export default PublicationsPage;
