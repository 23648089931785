import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { gql } from "@apollo/client";
import { useQueryParam, ArrayParam } from "use-query-params";

import { unwrap } from "../components/WagtailWrapper";

import {
  GrayInset,
  Grid,
  WideContent,
  Section,
  Content,
  TightContent,
  Frame,
} from "../components/Layouts";
import { ListingHeader } from "../components/ListingHeader";
import { EmptyList } from "../components/EmptyList";
import { ArticleCard, ArticleCardFragment } from "../components/Card";
import { SectionFragment } from "../components/Wagtail";
import EmptyImage from "../images/undraw_secure_data_0rwp.svg";
import { Filters } from "../components/Facets";

const getFacets = (items = []) =>
  [
    {
      label: "Authors",
      options: Object.values(
        items?.reduce(
          (acc, { articleAuthors = [] }) =>
            articleAuthors?.reduce((acc, { articleAuthor }) => {
              const text = articleAuthor?.name;
              if (text && !acc[text]) {
                acc[text] = { label: text, value: text };
              }
              return acc;
            }, acc),
          {}
        )
      ),
    },
  ].sort((a, b) => a.options.length - b.options.length);

const normalize = (items = []) => {
  return items
    ?.filter((p) => p?.lastPublishedAt)
    ?.map((item) => {
      item.tags = [
        ...(item?.articleAuthors || [])?.map(
          ({ articleAuthor }) => articleAuthor?.name
        ),
      ].filter(Boolean);

      return item;
    })
    .sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return (
        new Date(b.goLive || b.firstPublishedAt) -
        new Date(a.goLiveAt || a.firstPublishedAt)
      );
    });
};
export const Component = ({ page, snippets, images }) => {
  const [data, setData] = useState(page?.children);
  const [facets, setFacets] = useState({});
  const [items, setItems] = useState(data);
  const [filters, setFilters] = useQueryParam("filters", ArrayParam);

  useEffect(() => {
    setData(normalize(data));
  }, [page]);

  useEffect(() => {
    setItems(
      data?.filter((e) =>
        filters ? filters.every((f) => e.tags?.includes(f)) : true
      )
    );
  }, [filters, data]);

  useEffect(() => {
    setFacets(getFacets(items));
  }, [items]);

  return (
    <Frame page={page} url={`news`} title={page?.title}>
      <Section>
        <Content>
          <ListingHeader images={images} page={page} snippets={snippets} />
        </Content>
        <TightContent>
          <Filters facets={facets} filters={filters} setter={setFilters} />
        </TightContent>
      </Section>
      <EmptyList
        items={items}
        message={"No Articles Found."}
        image={EmptyImage}
      />
      {!!(items?.length > 0) && (
        <GrayInset>
          <WideContent>
            <Grid>
              {items?.map((m) => (
                <ArticleCard key={m.slug} item={m} />
              ))}
            </Grid>
          </WideContent>
        </GrayInset>
      )}
    </Frame>
  );
};

export const previewQuery = gql`
  query previewArticleListingPage($contentType: String!, $token: String!) {
    images {
      id
      title
      src
    }
    snippets {
      __typename
      ... on CallToAction {
        name
        url
        document {
          id
          file
        }
        id
      }
    }
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoTitle
      seoDescription
      ... on ArticleListingPage {
        header
        subheader
        headerText {
          ...SectionFragment
        }
        headerImage {
          title
          src
        }
        children {
          lastPublishedAt
          ...ArticleCardFragment
        }
      }
    }
  }
  ${ArticleCardFragment}
  ${SectionFragment}
`;

export const query = graphql`
  query ArticleListingPage {
    wagtail {
      images {
        id
        title
        src
      }
      snippets {
        __typename
        ... on Wagtail_CallToAction {
          name
          url
          document {
            id
            file
          }
          id
        }
      }
      page(slug: "news") {
        id
        slug
        title
        seoTitle
        seoDescription
        ... on Wagtail_ArticleListingPage {
          header
          subheader
          headerText {
            ...Wagtail_SectionFragment
          }
          headerImage {
            title
            src
          }
          children {
            lastPublishedAt
            ...Wagtail_ArticleCardFragment
          }
        }
      }
    }
  }
`;

export default unwrap(Component);
