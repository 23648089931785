import * as React from "react";

export const PreviewBar = () => (
  <div className="bg-blue-900 text-white">
    <div className="max-w-screen-xl mx-auto p-3">
      <div className="flex items-center justify-between flex-wrap">
        <div className="w-0 flex-1 flex items-center">
          <span className="hidden lg:flex  mr-4">
            <svg
              fill="none"
              className="h-6 w-6 text-white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
            </svg>
          </span>
          <p className="font-sm text-white">
            You are previewing draft changes. These changes may not yet be live.
          </p>
        </div>
      </div>
    </div>
  </div>
);
