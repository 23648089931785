import React from "react";
import { graphql } from "gatsby";
import { gql } from "@apollo/client";

import { unwrap } from "../components/WagtailWrapper";

import { Wagtail, SectionFragment } from "../components/Wagtail";

import { BannerFrame, Content, Section } from "../components/Layouts";
import { RichText } from "../components/RichText";
import { Secondary } from "../components/Buttons";
import { Prose } from "../components/Prose";
import { Image } from "../components/Image";

export const Component = ({ page, snippets, images }) => (
  <BannerFrame
    page={page}
    url={page?.slug}
    title={page?.title}
    snippets={snippets}
    images={images}
  >
    <Section>
      <Content>
        <Prose>
          <h2>Informatics Portals</h2>
        </Prose>
        <ul>
          {page?.informaticsTools?.map(({ informaticsTool, link }) => (
            <li key={informaticsTool.id}>
              <Prose>
                <div className="md:flex">
                  <div className="flex-shrink-0 w-56 mb-5 md:mt-12">
                    <Image
                      src={informaticsTool?.image?.src}
                      alt={informaticsTool?.image?.title}
                      height={100}
                    />
                  </div>
                  <div className="md:ml-16 flex-auto">
                    <h3>{informaticsTool.name}</h3>
                    <RichText html={informaticsTool.description} />
                    <div className="mt-3">
                      <Secondary url={link || informaticsTool.link}>
                        Learn More
                      </Secondary>
                    </div>
                  </div>
                </div>
              </Prose>
            </li>
          ))}
        </ul>
      </Content>
    </Section>
    <Section>
      <Content>
        <Wagtail
          sections={page?.sections}
          snippets={snippets}
          images={images}
        />
      </Content>
    </Section>
  </BannerFrame>
);

export const previewQuery = gql`
  query previewInformaticsPortalsPage($contentType: String!, $token: String!) {
    images {
      id
      title
      src
    }
    snippets {
      __typename
      ... on CallToAction {
        name
        url
        document {
          id
          file
        }
        id
      }
    }
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoTitle
      seoDescription
      ... on InformaticsPortalsPage {
        header
        subheader
        headerText {
          ...SectionFragment
        }
        headerImage {
          title
          file
          src
        }
        informaticsTools(order: "sort_order") {
          informaticsTool {
            id
            name
            description
            link
            image {
              title
              src
            }
          }
          link
        }
        sections {
          ...SectionFragment
        }
      }
    }
  }
  ${SectionFragment}
`;

export const query = graphql`
  query InformaticsPortalsPage($id: Int) {
    wagtail {
      images {
        id
        title
        src
      }
      snippets {
        __typename
        ... on Wagtail_CallToAction {
          name
          url
          document {
            id
            file
          }
          id
        }
      }
      page(id: $id) {
        id
        slug
        title
        seoTitle
        seoDescription
        ... on Wagtail_InformaticsPortalsPage {
          header
          subheader
          headerText {
            ...Wagtail_SectionFragment
          }
          headerImage {
            title
            file
            src
          }
          informaticsTools(order: "sort_order") {
            informaticsTool {
              id
              name
              description
              link
              image {
                title
                src
              }
            }
            link
          }
          sections {
            ...Wagtail_SectionFragment
          }
        }
      }
    }
  }
`;

export default unwrap(Component);
