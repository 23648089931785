import React from "react";
import { graphql, Link } from "gatsby";
import { gql } from "@apollo/client";

import { unwrap } from "../components/WagtailWrapper";
import { RichText } from "../components/RichText";
import {
  Content,
  TightContent,
  Section,
  Frame,
  SmallGrid,
} from "../components/Layouts";

import { Title, Prose } from "../components/Prose";
import { Primary } from "../components/Buttons";
import { Breadcrumbs } from "../components/SEOTags";
import { FoundationImage } from "../components/Images";
import {
  DonorLevel,
  ExperimentCard,
  ExperimentCardFragment,
  HistologyCard,
  HistologyCardFragment,
  InstitutionCard,
  InstitutionCardFragment,
  PersonCard,
  PersonCardFragment,
} from "../components/Card";
import { MinimalHeader } from "../components/MinimalHeader";
import { colorList } from "../components/Colors";
import { Facet } from "../components/Facets";
import { RelatedContent } from "../components/RelatedContent";

export const Component = ({ page }) => (
  <Frame page={page} url={`partnerships/${page?.slug}`} title={page?.title}>
    <Section>
      <Content>
        <header>
          <Breadcrumbs
            url={"partnerships"}
            list={"Foundation and Family Partnerships"}
            title={page?.title}
          />
          <Title>{page?.title}</Title>
          {page?.contactEmail && (
            <a
              href={`mailto:${page?.contactEmail}`}
              className="mt-3 mr-3 flex items-center text-sm leading-5 sm:mr-6 text-blue-600 hover:underline visited:text-purple-600"
            >
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
              </svg>
              Send Email
            </a>
          )}
          {page?.location && (
            <div className="mt-2 flex items-center text-sm leading-5  sm:mr-6">
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                />
              </svg>
              {page?.location}
            </div>
          )}
          {[page?.donorLevel, ...page?.foundationTypes]
            .filter(Boolean)
            ?.map((h) => (
              <Link key={h?.name} to={`/partnerships?filters=${h?.name}`}>
                <Facet color={colorList[(h?.name).length % colorList.length]}>
                  {h?.name}
                </Facet>
              </Link>
            ))}
          {!!page?.yearsSupporting && (
            <Link
              to={`/partnerships?filters=${
                page?.yearsSupporting < 5
                  ? "0-5"
                  : page?.yearsSupporting < 10
                  ? "5-10"
                  : page?.yearsSupporting < 15
                  ? "10-15"
                  : "15%2B"
              }`}
            >
              <Facet
                color={
                  colorList[
                    `${page?.yearsSupporting} year${
                      page?.yearsSupporting > 0 && "s"
                    }`.length % colorList.length
                  ]
                }
              >
                {page?.yearsSupporting} year
                {page?.yearsSupporting > 1 ? "s" : ""}
              </Facet>
            </Link>
          )}
        </header>
      </Content>
    </Section>
    <div className="bg-gray-100">
      <div className="relative">
        <div className="absolute inset-0 h-1/2 bg-white"></div>
        <div className="relative max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden">
            <div className="bg-white relative w-full h-64">
              <div className="flex justify-center items-center h-full w-full px-12">
                <FoundationImage image={page?.image} height={200} />
              </div>
            </div>
          </div>
          <span className="absolute bottom-0 left-1/2 h-12 w-12 rounded-full text-white shadow-lg bg-white -mb-6 -ml-6 flex justify-center items-center">
            <DonorLevel level={page?.donorLevel?.name} />
          </span>
        </div>
      </div>
    </div>

    <Section theme="dark" shadow={false}>
      {page?.link && (
        <div className="flex space-x-6 justify-center">
          {page?.link && (
            <Primary variant="small" url={page?.link}>
              Learn more
            </Primary>
          )}
        </div>
      )}

      {page?.description && (
        <TightContent>
          <Prose>
            {page?.description && (
              <>
                <h2>Mission</h2>
                <RichText html={page?.description} />
              </>
            )}
          </Prose>
        </TightContent>
      )}
    </Section>
    {page?.backing.length > 0 && (
      <Section>
        <TightContent>
          <MinimalHeader
            subTitle="backing the following"
            title="Scientific Projects"
          />
          <SmallGrid>
            {page?.backing.map((exp) => (
              <ExperimentCard key={exp.slug} item={exp} />
            ))}
          </SmallGrid>
        </TightContent>
        <TightContent>
          <MinimalHeader
            subTitle="backing the following"
            title="Principal Investigators"
          />
          <SmallGrid>
            {page?.backing.map((exp) => (
              <PersonCard
                key={exp?.principalInvestigator?.slug}
                item={exp.principalInvestigator}
              />
            ))}
          </SmallGrid>
        </TightContent>
        <TightContent>
          <MinimalHeader
            subTitle="backing the following"
            title="Institutions"
          />
          <SmallGrid>
            {page?.backing.map((exp) => (
              <InstitutionCard
                key={exp?.principalInvestigator?.memberInstitution?.slug}
                item={exp?.principalInvestigator?.memberInstitution}
              />
            ))}
          </SmallGrid>
        </TightContent>
        <TightContent>
          <MinimalHeader
            subTitle="backing research on"
            title="Brain Tumor Types"
          />
          <SmallGrid>
            {page?.backing.map((exp) => (
              <>
                {exp.histologies.map((hist) => (
                  <HistologyCard key={hist?.slug} item={hist} />
                ))}
              </>
            ))}
          </SmallGrid>
        </TightContent>
        <RelatedContent page={page} />
      </Section>
    )}
  </Frame>
);

export const previewQuery = gql`
  query previewFoundationPartnershipPage(
    $contentType: String!
    $token: String!
  ) {
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoTitle
      seoDescription
      ... on FoundationPartnershipPage {
        link
        description
        image: logo {
          title
          src
        }
        foundationTypes {
          name
        }
        location
        contactEmail
        donorLevel {
          name
        }
        yearsSupporting
        backing {
          ...ExperimentCardFragment
          principalInvestigator {
            ...PersonCardFragment
            memberInstitution {
              ...InstitutionCardFragment
            }
          }
          histologies {
            ...HistologyCardFragment
          }
        }
      }
    }
  }
  ${ExperimentCardFragment}
  ${PersonCardFragment}
  ${InstitutionCardFragment}
  ${HistologyCardFragment}
`;

export const query = graphql`
  query FoundationPartnershipPage($id: Int) {
    wagtail {
      page(id: $id) {
        id
        slug
        title
        seoTitle
        seoDescription
        ... on Wagtail_FoundationPartnershipPage {
          link
          description
          image: logo {
            title
            src
          }
          foundationTypes {
            name
          }
          location
          contactEmail
          donorLevel {
            name
          }
          yearsSupporting
          backing {
            ...Wagtail_ExperimentCardFragment
            principalInvestigator {
              ...Wagtail_PersonCardFragment
              memberInstitution {
                ...Wagtail_InstitutionCardFragment
              }
            }
            histologies {
              ...Wagtail_HistologyCardFragment
            }
          }
        }
      }
    }
  }
`;

export default unwrap(Component);
