import React from "react";
import { graphql, Link } from "gatsby";
import { gql } from "@apollo/client";
import { format } from "date-fns";

import { unwrap } from "../components/WagtailWrapper";
import { RichText } from "../components/RichText";
import {
  SmallGrid,
  Content,
  TightContent,
  Section,
  Frame,
} from "../components/Layouts";
import { Facet } from "../components/Facets";
import { PresentationImage, BackerImage } from "../components/Images";
import {
  PersonCardFragment,
  ExperimentCardFragment,
  PresentationCardFragment,
  PublicationCardFragment,
} from "../components/Card";

import { RelatedContent } from "../components/RelatedContent";
import { Title, Prose } from "../components/Prose";
import { Primary, Secondary } from "../components/Buttons";
import { Breadcrumbs } from "../components/SEOTags";
import { colorList } from "../components/Colors";

export const Component = ({ page }) => (
  <Frame page={page} url={`presentations/${page?.slug}`} title={page?.title}>
    <Section>
      <Content>
        <header>
          <Breadcrumbs
            url={"presentations"}
            list={"Presentations"}
            title={page?.title}
          />
          <Title>{page?.title}</Title>
          <div className="mt-3 flex items-center space-x-1">
            <span>Presented</span>
            {page?.conference?.name && (
              <>
                <span>at</span>
                <div>
                  <Facet
                    style={{ margin: 0 }}
                    color={
                      colorList[
                        (page?.conference?.name).length % colorList.length
                      ]
                    }
                  >
                    <Link
                      to={`/presentations?filters=${page?.conference?.name}`}
                    >
                      {page?.conference?.name}
                    </Link>
                  </Facet>
                </div>
              </>
            )}
            <span>on</span>
            <svg
              className="flex-shrink-0 ml-2 mr-1.5 h-5 w-5 text-gray-700"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clipRule="evenodd"
              />
            </svg>
            {page?.presentationDate && (
              <time dateTime={page?.presentationDate}>
                {format(
                  new Date(
                    page?.presentationDate.replace(/-/g, "/").replace(/T.+/, "")
                  ),
                  "MMMM dd, yyyy"
                )}
              </time>
            )}
          </div>
          {page?.authorsText && (
            <div className="mt-3 italic">{page?.authorsText}</div>
          )}
        </header>
      </Content>
    </Section>
    <div className="bg-gray-100">
      <div className="relative">
        <div className="absolute inset-0 h-1/2 bg-white"></div>
        <div className="relative max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden">
            <div className="bg-white relative w-full h-64">
              <div className="absolute h-full w-full object-cover">
                <PresentationImage image={page?.image} width={700} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Section theme="dark" shadow={false}>
      {(page?.link || page?.slidesLink) && (
        <div className="flex space-x-6 justify-center">
          {page?.link && (
            <Primary variant="small" url={page?.link}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-4 w-4 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
              Watch the presentation
            </Primary>
          )}
          {page?.slidesLink && (
            <Secondary variant="small" url={page?.slidesLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-4 w-4 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                />
              </svg>
              View the slides
            </Secondary>
          )}
        </div>
      )}
      {(page?.description ||
        page?.acknowledgements ||
        page?.sponsors?.length > 0) && (
        <TightContent>
          <Prose>
            {page?.description && (
              <>
                <h2>Summary</h2>
                <RichText html={page?.description} />
              </>
            )}
            {page?.acknowledgements && (
              <>
                <h2>Acknowledgements</h2>
                <p>{page?.acknowledgements}</p>
              </>
            )}
            {page?.sponsors?.length > 0 && (
              <>
                <h2>Funders</h2>
                <SmallGrid>
                  {page?.sponsors.map((backer) => (
                    <div
                      key={backer.name}
                      className="col-span-1 flex items-center bg-white border border-gray-100 rounded-md shadow-sm overflow-hidden"
                    >
                      <div className="flex-shrink-0 flex items-center justify-center w-16 h-16 text-white text-center text-sm leading-5 font-medium bg-blue-100">
                        <BackerImage image={backer.image} />
                      </div>
                      <div className="flex-1 px-4 py-2 truncate">
                        <a
                          href="#"
                          className=" text-sm leading-5 font-medium hover: transition ease-in-out duration-150"
                        >
                          {backer.name}
                        </a>
                      </div>
                    </div>
                  ))}
                </SmallGrid>
              </>
            )}
          </Prose>
        </TightContent>
      )}
    </Section>
    <Section>
      <RelatedContent page={page} />
    </Section>
  </Frame>
);

export const previewQuery = gql`
  query previewPresentationPage($contentType: String!, $token: String!) {
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoTitle
      seoDescription
      ... on PresentationPage {
        image {
          src
          title
        }
        presentationDate
        description
        short
        authorsText
        sponsors {
          name
          image {
            src
            title
          }
        }
        acknowledgements
        conference {
          name
        }
        link
        slidesLink
        relatedScientificProjects {
          relatedScientificProject {
            ...ExperimentCardFragment
          }
        }
        relatedPresentations {
          relatedPresentation {
            ...PresentationCardFragment
          }
        }
        relatedPublications {
          relatedPublication {
            ...PublicationCardFragment
          }
        }
        presentations {
          ...PresentationCardFragment
        }
        scientificProjects {
          ...ExperimentCardFragment
        }
        publications {
          ...PublicationCardFragment
        }
      }
    }
  }
  ${PersonCardFragment}
  ${PresentationCardFragment}
  ${ExperimentCardFragment}
  ${PublicationCardFragment}
`;

export const query = graphql`
  query presentationPage($id: Int) {
    wagtail {
      page(id: $id) {
        id
        slug
        title
        seoTitle
        seoDescription
        ... on Wagtail_PresentationPage {
          image {
            src
            title
          }
          presentationDate
          description
          short
          authorsText
          sponsors {
            name
            image {
              src
              title
            }
          }
          acknowledgements
          conference {
            name
          }
          link
          slidesLink
          relatedScientificProjects {
            relatedScientificProject {
              ...Wagtail_ExperimentCardFragment
            }
          }
          relatedPresentations {
            relatedPresentation {
              ...Wagtail_PresentationCardFragment
            }
          }
          relatedPublications {
            relatedPublication {
              ...Wagtail_PublicationCardFragment
            }
          }
          presentations {
            ...Wagtail_PresentationCardFragment
          }
          scientificProjects {
            ...Wagtail_ExperimentCardFragment
          }
          publications {
            ...Wagtail_PublicationCardFragment
          }
        }
      }
    }
  }
`;

export default unwrap(Component);
