import React from "react";
import { graphql } from "gatsby";
import { gql } from "@apollo/client";

import { unwrap } from "../components/WagtailWrapper";
import {
  GrayInset,
  Grid,
  WideContent,
  Column,
  BannerFrame,
  Framed,
} from "../components/Layouts";

import { FoundationCard, FoundationCardFragment } from "../components/Card";
import { SectionFragment } from "../components/Wagtail";

export const Component = ({ page, snippets, images }) => {
  const items = (page?.foundations || [])?.sort(function (a, b) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });

  return (
    <BannerFrame
      images={images}
      page={page}
      snippets={snippets}
      url={`partnerships`}
      title={page?.title}
    >
      <Framed>
        <Column>
          {!!(items?.length > 0) && (
            <GrayInset>
              <WideContent>
                <h2 className="my-12  text-3xl leading-9 font-semibold tracking-tight sm:text-4xl sm:leading-10">
                  Foundation and Family Partnerships
                </h2>
                <Grid>
                  {items.map((m) => (
                    <FoundationCard showType={false} key={m.slug} item={m} />
                  ))}
                </Grid>
              </WideContent>
            </GrayInset>
          )}
        </Column>
      </Framed>
    </BannerFrame>
  );
};

export const previewQuery = gql`
  query previewFoundationListingPage($contentType: String!, $token: String!) {
    images {
      id
      title
      src
    }
    snippets {
      __typename
      ... on CallToAction {
        name
        url
        document {
          id
          file
        }
        id
      }
    }
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoTitle
      seoDescription
      ... on FoundationPartnershipListingPage {
        header
        subheader
        headerText {
          ...SectionFragment
        }
        headerImage {
          title
          src
        }
        foundations {
          lastPublishedAt
          ...FoundationCardFragment
        }
      }
    }
  }

  ${SectionFragment}
  ${FoundationCardFragment}
`;

export const query = graphql`
  query FoundationListingPage {
    wagtail {
      images {
        id
        title
        src
      }
      snippets {
        __typename

        ... on Wagtail_CallToAction {
          name
          url
          document {
            id
            file
          }
          id
        }
      }
      page(slug: "foundations") {
        id
        slug
        title
        seoTitle
        seoDescription
        ... on Wagtail_FoundationPartnershipListingPage {
          header
          subheader
          headerText {
            ...Wagtail_SectionFragment
          }
          headerImage {
            title
            src
          }
          foundations {
            lastPublishedAt
            ...Wagtail_FoundationCardFragment
          }
        }
      }
    }
  }
`;

export default unwrap(Component);
