import React from "react";
import { graphql } from "gatsby";
import { gql } from "@apollo/client";

import { unwrap } from "../components/WagtailWrapper";

import { Wagtail, SectionFragment } from "../components/Wagtail";

import { BannerFrame, Content, Section } from "../components/Layouts";

export const Component = ({ page, snippets, images }) => (
  <BannerFrame
    page={page}
    url={page?.slug}
    title={page?.title}
    snippets={snippets}
    images={images}
  >
    <Section>
      <Content>
        <Wagtail
          sections={page?.sections}
          snippets={snippets}
          images={images}
        />
      </Content>
    </Section>
  </BannerFrame>
);

export const previewQuery = gql`
  query previewStandardPage($contentType: String!, $token: String!) {
    images {
      id
      title
      src
    }
    snippets {
      __typename
      ... on CallToAction {
        name
        url
        document {
          id
          file
        }
        id
      }
    }
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoTitle
      seoDescription
      ... on StandardPage {
        header
        subheader
        headerText {
          ...SectionFragment
        }
        headerImage {
          title
          file
          src
        }
        sections {
          ...SectionFragment
        }
      }
    }
  }
  ${SectionFragment}
`;

export const query = graphql`
  query StandardPage($id: Int) {
    wagtail {
      images {
        id
        title
        src
      }
      snippets {
        __typename
        ... on Wagtail_CallToAction {
          name
          url
          document {
            id
            file
          }
          id
        }
      }
      page(id: $id) {
        id
        slug
        title
        seoTitle
        seoDescription
        ... on Wagtail_StandardPage {
          header
          subheader
          headerText {
            ...Wagtail_SectionFragment
          }
          headerImage {
            title
            file
            src
          }
          sections {
            ...Wagtail_SectionFragment
          }
        }
      }
    }
  }
`;

export default unwrap(Component);
